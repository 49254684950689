<template>
    <div id="components-list">
        <div id="main-title">Liste des composants</div>
        <div class="title">Buttons</div>
        <div class="row">
            <CustomButton :label="'bouton'" />
            <div class="label">Bouton par default</div>

            <CustomButton :label="'avec symbol'" :symbol="'add'" />
            <div class="label">Bouton avec symbole</div>

        </div>
        <div class="row">

            <CustomButton :label="'type action'" :type="'action'" />
            <div class="label">Bouton type action</div>

            <CustomButton :label="'type cancel'" :type="'cancel'" />
            <div class="label">Bouton type cancel</div>

            <CustomButton :label="'type validate'" :type="'validate'" />
            <div class="label">Bouton type validate</div>
        </div>

        <div class="title">Checkbox</div>
        <div class="row">
            <Checkbox />
            <div class="label">checkbox</div>
        </div>

        <div class="title">Switch</div>
        <div class="row">
            <Switch />
            <div class="label">switch</div>
        </div>

    </div>
</template>

<script>

import CustomButton from "../../components/basics/Button.vue";
import Checkbox from "../../components/basics/Checkbox.vue";
import Switch from "../../components/basics/Switch.vue";

export default {
    name: "Components-List",
    components: { CustomButton, Checkbox, Switch },
};
</script>

<style lang="less" scoped>
#components-list {
    min-height: 100vh;
    padding: 36px;
    user-select: none;

    #main-title {
        .title-large-font();
        text-align: center;
        font-weight: bold;
    }

    .title {
        .title-large-font();
        margin-top: 48px;
    }

    .row {
        margin-top: 24px;
        .flex-row();
    }

    .label {
        margin-left: 12px;
        margin-right: 36px;
        .label-large-font();
        .flex-row-v-center();
    }
}
</style>